<template>
    <v-card elevation="0">
        <v-card-title>
            Логи
            <v-icon class="mx-4" v-on:click="refreshLog">
                mdi-refresh
            </v-icon>
            <v-spacer/>
            <v-text-field
                    style="width: 20vw;"
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Поискнуть"
                    single-line
                    hide-details
            ></v-text-field>
        </v-card-title>

        <v-row>
            <v-col cols="9">

                <v-data-table
                        :headers="headers"
                        :items="logs"
                        :search="search"

                >
                    <template v-slot:item.Session="{item}">
                        <a v-on:click="addSession(item.Session)">{{item.Session}}</a>
                    </template>
                    <template v-slot:item.Source="{item}">
                        <a v-on:click="addSource(item.Source)">{{item.Source}}</a>
                    </template>
                    <template  v-slot:item.Level="{item}">
                        <v-icon v-if="item.Level == 2" color="orange" v-on:click="addSeverity(item.Level)">
                            mdi-alert
                        </v-icon>
                        <v-icon v-if="item.Level == 3" color="red" v-on:click="addSeverity(item.Level)">
                            mdi-gift
                        </v-icon>
                    </template>
                </v-data-table>
            </v-col>

            <v-col cols="3">
                <v-card>
                    <v-card-title>
                        Фильтр
                    </v-card-title>
                    <v-row v-for="name of Object.keys(filters)" :key="`filter-${name}`" class="no-gutters">
                        <v-col cols="4">
                            <v-card-text>
                                {{name}}
                            </v-card-text>
                        </v-col>
                        <v-col cols="7">
                            <v-text-field v-model="filters[name]" label="Значение">

                            </v-text-field>
                        </v-col>
                        <v-col align-self="center" v-if="filters[name]">
                            <v-icon color="red" small v-on:click="removeFitler(name)">mdi-close</v-icon>
                        </v-col>
                    </v-row>

                    <v-card-actions>
                        <v-btn block v-on:click="applyFilter">Применить</v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>

        <v-card-actions>
            <v-icon color="red" v-on:click="removeAllLogs">mdi-trash-can</v-icon>
        </v-card-actions>
    </v-card>
</template>

<script>
    import axios from 'axios';
    import {getURL} from "../../settings";

    export default {
        name: "logs",
        data: () => {
            return {
                headers: [{
                    text: 'Дата',
                    key: 'Datetime',
                    sortable: false,
                    value: 'Datetime',
                    width: '20%',
                }, {
                    text: 'Сессия',
                    key: 'Session',
                    sortable: false,
                    value: 'Session',
                    width: '10%'
                }, {
                    text: 'Источник',
                    key: 'Source',
                    sortable: false,
                    value: 'Source',
                    width: '10%'
                }, {
                    key: 'Level',
                    sortable: false,
                    value: 'Level',
                    width: '10%'
                }, {
                    text: 'Текст',
                    key: 'Text',
                    sortable: false,
                    value: 'Text',
                    width: '60%'
                }],
                logs: [],
                filters: {
                    'Сессия': null,
                    'Критичность': null,
                    'Источник': null,
                    'Сообщение': null,
                    'Исключение': null
                },
                search: null,
            }
        },
        mounted() {
            this.applyFilter();
        },
        methods: {
            addSource(source) {
              this.filters['Источник'] = source;
              this.applyFilter();
            },
            addSession(session) {
                this.filters['Сессия'] = session;
                this.applyFilter();
            },
            addSeverity(level) {
               this.filters['Критичность'] = level;
                this.applyFilter();
            },
            applyFilter() {
                axios.post(getURL('stat/list_filter'), this.filters, {withCredentials: true}).then(resp => {
                    this.logs = resp.data;
                })
            },
            removeFitler(name) {
                this.filters[name] = null;
                this.applyFilter();
            },
            refreshLog() {
                this.applyFilter();
            },
            removeAllLogs() {
                axios.get(getURL('stat/clear_log', {withCredentials: true})).then(() => {
                    this.applyFilter();
                })
            }
        },
    }
</script>

<style scoped>

</style>
