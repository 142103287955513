<template>
    <v-card>
        <v-card-title>
            Статистика
        </v-card-title>

    </v-card>
</template>

<script>
    export default {
        name: "statistics"
    }
</script>

<style scoped>

</style>
