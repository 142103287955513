<template>
    <v-card elevation="0" class="mx-auto" color="transparent">
        <audio id="mySound" class="my_audio hidden" style="display: none" controls preload="none">
            <source src="../../assets/new_message.mp3" type="audio/mpeg">
        </audio>

        <v-card-title>
            Панель состояния

            <v-tooltip bottom>
                <template v-slot:activator="{on, attrs}">
                    <v-btn icon
                           v-on:click="accessAudio = !accessAudio"
                           class="ml-4">
                        <v-icon
                                v-on="on"
                                v-bind="attrs"
                        >{{accessAudio ? 'mdi-volume-high' : 'mdi-volume-off'}}
                        </v-icon>
                    </v-btn>
                </template>
                <span>Звуковые уведомления</span>
            </v-tooltip>

        </v-card-title>
        <v-row>
            <v-col cols="12" class="py-0">
                <OrdersHistory icon="mdi-book-account-outline" name="Новые заказы"  :handler="updateState" handler-data="В работе" is-orders="true" :headers="[
                  {
                      text: 'Номер заказа',
                      key: 'Order',
                      sortable: false,
                      value: '@Order'
                  },
                  {
                      text: 'Дата',
                      value: 'Datetime',
                      sortable: false
                  },
                  {
                      text: 'Сумма',
                      value: 'Amount',
                      sortable: false
                  },
                  {
                      text: 'Номер телефона',
                      value: 'Phone',
                      sortable: false
                  },
                  {
                    text: 'Срочность',
                    value: 'Urgency',
                    sortable: false
                  },
                  {
                      text: 'Загрузить',
                      value: 'AppendIcon',
                    sortable: false
                  }
              ]"
                               :history="currentOrders"/>
            </v-col>
            <v-col cols="12" class="py-0">
                <OrdersHistory icon="mdi-book-account-outline" name="В работе" :handler="updateState" :download-only="true" handler-data="Готово" is-orders="true" :headers="[
                  {
                      text: 'Номер заказа',
                      key: 'Order',
                      sortable: false,
                      value: '@Order'
                  },
                  {
                      text: 'Дата',
                      value: 'Datetime',
                      sortable: false
                  },
                  {
                      text: 'Сумма',
                      value: 'Amount',
                      sortable: false
                  },
                  {
                      text: 'Номер телефона',
                      value: 'Phone',
                      sortable: false
                  },
                  {
                      text: 'Выполнено',
                      value: 'ChangeState',
                    sortable: false
                  },
                  {
                      text: 'Загрузить',
                      value: 'AppendIcon',
                    sortable: false
                  }
              ]"
                               :history="inProcessOrders"/>
            </v-col>
            <v-col cols="12" class="py-0">
                <OrdersHistory icon="mdi-book-account-outline" name="Готово" :handler="updateState" handler-data="Оплачен" is-orders="true" :headers="[
                  {
                      text: 'Номер заказа',
                      key: 'Order',
                      sortable: false,
                      value: '@Order'
                  },
                  {
                      text: 'Дата',
                      value: 'Datetime',
                      sortable: false
                  },
                  {
                      text: 'Сумма',
                      value: 'Amount',
                      sortable: false
                  },
                  {
                      text: 'Номер телефона',
                      value: 'Phone',
                      sortable: false
                  },
                  {
                      text: 'Выполнено',
                      value: 'ChangeState',
                    sortable: false
                  }
              ]"
                               :history="readyOrders"/>
            </v-col>
            <v-col cols="12" class="py-0">
                <OrdersHistory icon="mdi-book-account-outline" name="Оплачено" is-orders="true" :headers="[
                  {
                      text: 'Номер заказа',
                      key: 'Order',
                      sortable: false,
                      value: '@Order'
                  },
                  {
                      text: 'Дата',
                      value: 'Datetime',
                      sortable: false
                  },
                  {
                      text: 'Сумма',
                      value: 'Amount',
                      sortable: false
                  },

                  {
                      text: 'Номер телефона',
                      value: 'Phone',
                      sortable: false
                  }
              ]"
                               :history="payedOrders"/>
            </v-col>
            <v-col cols="4">
                <StatisitcCard v-bind="{
                title: 'Заказов',
                icon: 'mdi-book-account-outline',
                amount: orders,
                action: 'Подробнее...',
                link: '/orders'
}"/>

            </v-col>
            <v-col cols="4">
                <StatisitcCard v-bind="{
                title: 'Оплачено',
                icon: 'mdi-credit-card-outline',
                amount: sells,
                action: 'Подробнее...',
                link: '/orders'
}"/>
            </v-col>
            <v-col cols="4">
                <StatisitcCard v-bind="{
                title: 'Уникальных клиентов',
                icon: 'mdi-account',
                amount: clients,
                action: 'Подробнее...',
                link: '/orders'
}"/>
            </v-col>

            <v-col cols="6">
                <OrdersHistory icon="mdi-cellphone" name="Ожидают звонка" is-orders="true" :headers="[
                  {
                      text: 'Номер заказа',
                      value: '@Order',
                      sortable: false
                  },
                  {
                      text: 'Дата',
                      value: 'Datetime',
                      sortable: false
                  },
                  {
                      text: 'Номер телефона',
                      value: 'Phone',
                      sortable: false
                  }
              ]"
                               :history="waitCalls"/>
            </v-col>
            <v-col cols="6">
                <OrdersHistory icon="mdi-calendar-account" name="Активность в админке" :headers="[
                  {
                      text: 'Дата',
                      sortable: false,
                      value: 'Datetime'
                  },
                  {
                      text: 'Действие',
                      value: 'Action',
                      sortable: false
                  },
                  {
                      text: 'Имя пользователя',
                      value: 'Name',
                      sortable: false
                  }
              ]" :history="journal"/>
            </v-col>

        </v-row>
    </v-card>
</template>

<script>

    import OrdersHistory from "../../components/OrdersHistory";
    import StatisitcCard from "../../components/StatisitcCard";

    import axios from 'axios';

    import {getURL, getWSSUrl} from "../../settings";
    export default {
        name: 'Home',
        data: () => {
            return {
                orders: 0,
                sells: 0,
                clients: 0,
                journal: [],
                lastOrders: [],
                waitCalls: [],
                audio: null,
                accessAudio: false,
                currentOrders: [],
                inProcessOrders: [],
                readyOrders: [],
                payedOrders: [],
                socketIsOpen: false,
            }
        },
        mounted() {
            this.audio = new Audio('../../assets/new_message.mp3');
            this.connectSocket();
            this.loadStat();
        },
        methods: {
            connectSocket() {
                this.websocket = new WebSocket(getWSSUrl('ws?purpose=service'));
                this.websocket.onopen = (e) => {
                    console.log('socket opened ', e);
                    this.socketIsOpen = true;
                };

                this.websocket.onclose  = (e) => {
                    console.log('socket closed ', e);
                    this.socketIsOpen = false;
                    setInterval(() => {
                        if(!this.socketIsOpen)
                            this.connectSocket();
                    }, 10000)
                };

                this.websocket.onerror = (e) => {
                    console.log('socket on error', e)
                }

                this.websocket.onmessage = (e) => {
                    const ws_message = e.data;
                    let msg_tokens = ws_message.split(':');
                    if(msg_tokens
                        && msg_tokens.length === 2
                        && msg_tokens[0] === 'order_created' ) {
                        this.loadStat();
                        if(this.accessAudio)
                            document.getElementById('mySound').play();
                    }
                    console.log('socket on message ', e.data)
                }
            },
            updateState(item, status) {
                item; status;
                // axios.post(getURL('admin/order/update'), {
                //     id: item['@Order'],
                //     status: status
                // }, {withCredentials: true}).then(() => {
                //     this.loadStat();
                // })
            },
            loadStat() {
                axios.get(getURL('admin/stat')).then(resp => {
                    let data = resp.data;
                    this.orders = data['Orders'];
                    this.sells = data['Sells'];
                    this.clients = data['Clients'];
                    this.journal = data['Journal'];
                    this.lastOrders = data['LastOrders'];
                    this.waitCalls = data['CallWaits'];
                    const allOrders = data['AllOrders'];
                    this.currentOrders = allOrders.filter(o => {return o.Status === null});
                    this.currentOrders.sort((a, b) => {
                        if( a.Urgency === 'срочно' && b.Urgency === 'срочно')
                            return 0;

                        if( a.Urgency === 'срочно')
                            return -1;

                        if( b.Urgency === 'срочно' )
                            return 1;

                        return 0;
                    })
                    this.inProcessOrders = allOrders.filter(o => {return o.Status === 'В работе'});
                    this.readyOrders = allOrders.filter(o => {return o.Status === 'Готово' })
                    this.payedOrders = allOrders.filter(o => {return o.Status === 'Оплачен'})
                    console.log(this.journal)
                })
            }
        },
        components: {
            StatisitcCard,
            OrdersHistory
        },
    }
</script>
