<template>
    <v-card elevation="0" class="d-flex flex-column align-center">
        <v-card-title  v-if="!created">
            Создать задачу
        </v-card-title>
        <ShedulerDialog
                v-if="!created"
					:callback="addTask"
					action="Создать"
					:rolesList="rolesList"
                    :showCloseButton="false"
		></ShedulerDialog>
        <v-card-title v-if="created">Задача создана</v-card-title>
        <v-icon v-if="created" color="green">mdi-check</v-icon>
    </v-card>
    
</template>






<script>
import ShedulerDialog from '../dialogs/ShedulerDialog.vue'
import axios from 'axios';
import {getURL} from "../../settings";

    export default {
		name: 'ShedulerAddTask',
        components: { ShedulerDialog },

        data: () => {
		return {
            rolesList: [],
            created: false,
        }

        },

        mounted() {
            this.fetchRoles()


        },

        methods: {
            addTask(task) {
                axios.post(getURL('admin/sheduler/crud'), {...task, action: 'create'},  {withCredentials: true}).then(() => {
                    this.created = true;
			})
            },

            async fetchRoles(){
                const rolesResponse = await axios.get(getURL('admin/role/list'), {withCredentials: true});
                this.rolesList = rolesResponse.data;
            },
        }

    }
</script>



<style></style>
