import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/state/Home.vue'

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		name: 'Home',
		component: Home,
	},
	{
		path: '/products',
		name: 'Catalog',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () =>
			import(/* webpackChunkName: "about" */ '../views/store/Catalog.vue'),
	},
	{
		path: '/orders',
		name: 'Orders',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () =>
			import(/* webpackChunkName: "about" */ '../views/business/Orders.vue'),
	},
	{
		path: '/blocks',
		name: 'Blocks',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () =>
			import(/* webpackChunkName: "about" */ '../views/site/Blocks.vue'),
	},
	{
		path: '/information',
		name: 'Information',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () =>
			import(/* webpackChunkName: "about" */ '../views/site/Information.vue'),
	},
	{
		path: '/category',
		name: 'Category',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () =>
			import(/* webpackChunkName: "about" */ '../views/site/Category.vue'),
	},
	{
		path: '/content',
		name: 'Content',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () =>
			import(/* webpackChunkName: "about" */ '../views/site/Content.vue'),
	},
	{
		path: '/analyze',
		name: 'Analyze',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () =>
			import(/* webpackChunkName: "about" */ '../views/state/Analyze.vue'),
	},
	{
		path: '/loadmaster',
		name: 'LoadMaster',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () =>
			import(/* webpackChunkName: "about" */ '../views/store/LoadMaster.vue'),
	},
	{
		path: '/business',
		name: 'Hotel',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () =>
			import(/* webpackChunkName: "about" */ '../views/hotel/Hotel.vue'),
	},
	{
		path: '/tariffs',
		name: 'Tariffs',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () =>
			import(/* webpackChunkName: "about" */ '../views/business/Tariffs.vue'),
	},
	{
		path: '/main-settings',
		name: 'Config',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () =>
			import(/* webpackChunkName: "about" */ '../views/site/Config.vue'),
	},
	{
		path: '/employees',
		name: 'Employees',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () =>
			import(/* webpackChunkName: "about" */ '../views/user/Employees.vue'),
	},
	{
		path: '/tasks',
		name: 'Tasks',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () =>
			import(/* webpackChunkName: "about" */ '../views/docflow/Tasks.vue'),
	},
	{
		path: '/regulations',
		name: 'Regulations',
		component: () => import('../views/docflow/Regulation.vue'),
	},
	{
		path: '/rates',
		name: 'Rates',
		component: () => import('../views/store/Currency.vue'),
	},
	{
		path: '/conversation/:idx',
		name: 'Conversation',
		component: () => import('../views/docflow/Conversation.vue'),
	},
	{
		path: '/sheduler',
		name: 'Sheduler',
		component: () => import('../views/business/Sheduler.vue'),
	},
	{
		path: '/sheduler-add-task',
		name: 'ShedulerAddTask',
		component: () => import('../components/hotel/ShedulerAddTask.vue'),
	},
	{
		path: '/logs',
		name: 'Logs',
		component: () => import('../views/stat/logs.vue')
	},
	{
		path: '/statistics',
		name: 'Statistics',
		component: () => import('../views/stat/statistics.vue')
	}
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
